import {Col, Row} from "reactstrap";
import React from "react";
import WartelisteFeaturetteImage from "./WartelisteFeaturetteImage";
import WarteraumFeaturetteImageFirst from "./WarteraumFeaturetteFirst";
import WarteraumFeaturetteImageFourth from "./WarteraumFeaturetteFourth";
import WarteraumFeaturetteImageThird from "./WarteraumFeaturetteThird";
import WarteraumFeaturetteImageSecond from "./WarteraumFeaturetteSecond";

export const WartelisteFeaturetteFirst = ({imageTitle}) => (
    <Row className="featurette">
        <Col md="7" className="px-5">
            <div className="featurette-content">
                <div>
                    <h2 className="my-4">{imageTitle}</h2>
                    <p>
                        Die Tablet-App kann individuell an das Corporate Design Ihrer Ordination angepasst werden.
                    </p>
                    <p>
                        Patienten finden sich damit schnell in ihrem gewohnten Design wieder.
                    </p>
                    <p>
                        Darüber hinaus haben Sie eine einfache Möglichkeit zur Kommunikation aktueller Hinweise und Tipps.
                    </p>
                </div>
            </div>
        </Col>
        <Col md="5">
            <WarteraumFeaturetteImageFirst/>
        </Col>
    </Row>
);

export const WartelisteFeaturetteSecond = ({imageTitle}) => (
    <Row className="featurette">
        <Col md="5">
            <WarteraumFeaturetteImageSecond/>
        </Col>
        <Col md="7" className="px-5">
            <div className="featurette-content">
                <div>
                    <h2 className="my-4">{imageTitle}</h2>
                    <p>
                        Patienten-Stammdaten-Eingabe direkt am Tablet
                    </p>
                    <ul>
                        <li>Patienten können ihre Stammdaten direkt am Tablet in der Ordination eingeben</li>
                        <li>Die Sprechstundenhilfe spart Zeit bei der Datenübernahme und kann sich um andere Dinge kümmern</li>
                        <li>Die Patienten haben eine verkürzte subjektive Wartezeit da sie ihre Stammdaten am Tablet ausfüllen</li>
                    </ul>
                </div>
            </div>
        </Col>
    </Row>
);

export const WartelisteFeaturetteThird = ({imageTitle}) => (
    <Row className="featurette">
        <Col md="7" className="px-5">
            <div className="featurette-content">
                <div>
                    <h2 className="my-4">{imageTitle}</h2>
                    <p>
                        Abfrage über individuelle Vorlieben und Wünsche
                    </p>
                    <ul>
                        <li>Die Warteraum-App von medoboard kann einfach und flexibel an Ihre Anforderungen angepasst werden</li>
                        <li>Sie können die Patienten direkt nach besonderen Behandlungswünschen oder Beschwerden fragen</li>
                        <li>Durch die Eingabe am Tablet erfolgt die Patientenkommunikation im Warteraum sehr diskret</li>
                    </ul>
                </div>
            </div>
        </Col>
        <Col md="5">
            <WarteraumFeaturetteImageThird/>
        </Col>
    </Row>
);

export const WartelisteFeaturetteFourth = ({imageTitle}) => (
    <Row className="featurette">
        <Col md="5">
            <WarteraumFeaturetteImageFourth/>
        </Col>
        <Col md="7"  className="px-5">
            <div className="featurette-content">
                <div>
                    <h2 className="my-4">{imageTitle}</h2>
                    <p>
                        Die Warteraum-App von medoboard ist selbstverständlich DSGVO-konform.
                    </p>
                    <p>
                        Die vor der Behandlung benötigten Daten werden bereits in Ihrem Warteraum von den Patienten eigenständig eingegeben, validiert und automatisch in Ihre medoboard Ordinationssoftware eingepflegt. Ersparen Sie sich das mühsame Abtippen von Daten oder zeitintensive Befragen der Patienten und konzentrieren Sie sich auf das wirklich Wichtige.
                    </p>
                </div>
            </div>
        </Col>
    </Row>
);

export const WartelisteFeaturette = ({imageTitle}) => (
    <Row className="featurette">
        <Col md="7"  className="px-5">
            <div className="featurette-content">
                <div>
                    <h2 className="my-4">{imageTitle}</h2>
                    <p>
                        Die Patientendaten werden automatisch vom Tablet in Medoboard übernommen.
                    </p>
                    <p>
                        Ihre Patientenakte ist damit immer aktuell, mehrstufig validiert und vollständig.
                    </p>
                    <p>
                        Sie können die Patienten nach der Stammdatenerfassung einfach mit einem Klick aufrufen und haben
                        alle Informationen zur Verfügung.
                    </p>
                </div>
            </div>
        </Col>
        <Col md="5">
            <WartelisteFeaturetteImage/>
        </Col>
    </Row>
);