import {graphql, StaticQuery} from "gatsby";
import Img from "gatsby-image";
import React from "react";

const WarteraumFeaturetteImageSecond = () => (
    <StaticQuery
        query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "Warteraum-second.png" }) {
          childImageSharp {
            fluid(maxWidth: 500, maxHeight: 500) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
        render={data => <Img fluid={data.placeholderImage.childImageSharp.fluid}/>}
    />
);
export default WarteraumFeaturetteImageSecond;