import React from 'react'
import {Container, Row, Col} from 'reactstrap';
import Layout from '../components/layout';
import SEO from '../components/seo';
import {withPrefix} from 'gatsby';
import {Pricing} from '../components/pricing';
import WartelisteImage from "../components/WartelisteImage";
import {
    WartelisteFeaturette,
    WartelisteFeaturetteFirst, WartelisteFeaturetteFourth,
    WartelisteFeaturetteSecond, WartelisteFeaturetteThird
} from "../components/WartelisteFeaturette";

const Warteliste = (props) => (
    <Layout>
        <SEO googleTitle="Medoboard - optimiert Ihre Praxis"
             googleDescription="Medoboard organisiert Ihre Ordination mit intelligenten Funktionalitäten und optimiert Ihre Praxis."
             fbUrl="https://medoboard.com/warteliste"
             fbImage={withPrefix('medoboard-fb.png')}
             fbTitle="Medoboard - So schön kann warten sein"
             fbDescription="Medoboard unterstützt Sie in ihrer täglichen Arbeit mit dem Patienten damit Sie mehr Zeit für das Wesentliche haben"
             keywords={[`medoboard`, `Ordinationssoftware`, `intelligent`, `einfach`, `Patientenakte`, `Arztsoftware`]}/>
        <Container>
            <Row className="carousel">
                <Col md="12">
                    <WartelisteImage/>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <h2 className="featurette-heading">Wartelistenmanagement - einfach und effizient</h2>
                    <h4 className="text-muted  mb-5">Medoboard hat gemeinsam mit Ärzten ein einfaches und dabei effizientes Wartelistenmanagement umgesetzt</h4>
                    <p className="lead">
                        Das Medoboard Wartelistenmanagement umfasst:
                    </p>
                    <ul className="lead">
                        <li><b>Stammdaten-Erfassung durch den Patienten direkt am Tablet in der Ordination</b></li>
                        <p>
                            Damit wird die Sprechstundenhilfe entlastet und die subjektive Wartezeit für die Patienten
                            verkürzt da sie ihre Stammdaten selbst am Tablet ausfüllen
                        </p>
                        <li><b>Automatische Übernahme in die Patientenakte</b></li>
                        <p>
                            Die Stammdaten werden nach einer kurzen Kontrolle durch die Sprechstundenhilfe mit einem einfachen
                            Klick in die Patientenakte übernommen. Zeiteffizient und einfach.
                        </p>
                        <li><b>Einfacher Aufruf aus der Warteliste mit Überblick über Ankunftszeit, Wartezeit, uvm.</b></li>
                        <p>
                            Patienten werden einfach aus der Warteliste aufgerufen - und direkt mit der Patientenakte verlinkt.
                            Mit einem Klick alle Informationen auf einen Blick.
                        </p>
                    </ul>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Row>
                <Col md="12">
                    <WartelisteFeaturetteFirst imageTitle="Individuelles Corporate Design"/>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Row>
                <Col md="12">
                    <WartelisteFeaturetteSecond imageTitle="Stammdateneingabe am Tablet"/>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Row>
                <Col md="12">
                    <WartelisteFeaturetteThird imageTitle="Individuell anpassbar"/>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Row>
                <Col md="12">
                    <WartelisteFeaturetteFourth imageTitle="Absolut sicher"/>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Row>
                <Col md="12">
                    <WartelisteFeaturette imageTitle="Warteliste"/>
                </Col>
            </Row>
            <hr className="featurette-divider"/>
            <Pricing/>
        </Container>
    </Layout>
);

export default Warteliste;